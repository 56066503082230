<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="900px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" style="width:800px" label-width="120px">
                <el-form-item label="可提现金额">
                    ￥{{selectRow.totalBalance}}
                </el-form-item>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易分润">
                            ￥{{selectRow.posProfit}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="活动分润">
                            ￥{{selectRow.activeProfit}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="提现账户" prop="">
                    <el-select style="width:60%" @change="changeType"
                        v-model="selectRow.accountType"
                        placeholder="请选择提现账户">
                        <el-option label="交易分润" value="POS_PROFIT"></el-option>
                        <el-option label="活动分润" value="ACTIVE_PROFIT"></el-option>
                        <el-option label="流量卡分润" value="FLOW_PROFIT"></el-option>
                        <el-option label="其他分润" value="OTHER_PROFIT"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="提现金额">
                    <el-input v-model="selectRow.money" placeholder="请输入金额" style="width:60%; margin-right:10px"></el-input>
                    <el-button type="primary" @click="allWithdraw">全部提现</el-button>
                </el-form-item>
                <div style="padding-left: 50px;">
                    <p>提现说明:</p>
                    <p style="white-space: pre-wrap;">{{ tips }}
                    </p>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="cancel">取消</el-button>
                <el-button type="primary" @click="submitForm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ProfitManage } from '@/api';
export default {
    name: "CustomForm",
    props: {
        dicts: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isAdd: {
            type: Boolean,
            default: true,
        },
        selectRow: {
            type: Object,
            default: function () {
                return {}
            },
        },
        onFresh: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '分润账户提现',
            open: false,
            saveData: {},
            tips: ''
        }
    },
    watch: {
        onFresh: function (value) {
            this.open = value;
            if (value) {
                this.resetData();
            }
        }
    },
    mounted() {
        this.changeType()
    },
    methods: {
        allWithdraw() {
            if (this.selectRow.accountType == 'POS_PROFIT') {
                this.selectRow.money = this.selectRow.posProfit
            } else if (this.selectRow.accountType == 'ACTIVE_PROFIT') {
                this.selectRow.money = this.selectRow.activeProfit
            } else if (this.selectRow.accountType == 'FLOW_PROFIT') {
                this.selectRow.money = this.selectRow.flowProfit
            } else if (this.selectRow.accountType == 'OTHER_PROFIT') {
                this.selectRow.money = this.selectRow.otherProfit
            }
        },
        async resetData() {
            this.merchantNo = ''
            this.saveData = this.selectRow;
        },
        submitCheck() {
            this.$refs.saveOrEditForm.validate((valid) => {
                if (valid) {
                    this.submitForm()
                } else {
                    return false;
                }
            });
        },
        async submitForm() {
            let no = {
                POS_PROFIT: 'posAccountNo',
                ACTIVE_PROFIT: 'activeAccountNo',
                FLOW_PROFIT: 'flowAccountNo',
                OTHER_PROFIT: 'otherAccountNo',
            }
            this.selectRow.accountNo = this.selectRow[no[this.selectRow.accountType]]
            let result = await ProfitManage.extractionOrder.withdrawal(this.selectRow);
            if (result.success) {
                this.Message.success('提现成功');
                this.commitOnClose();
            }
        },
        cancel() {
            this.selectRow = {};
            this.commitOnClose();
        },
        commitOnClose() {
            this.$emit('on-close');
        },
        async changeType(val) {
            let no = {
                POS_PROFIT: 'posAccountNo',
                ACTIVE_PROFIT: 'activeAccountNo',
                FLOW_PROFIT: 'flowAccountNo',
                OTHER_PROFIT: 'otherAccountNo',
            }
            let accountNo = this.selectRow[no[this.selectRow.accountType]]
            let result = await ProfitManage.extractionOrder.beforeWithdrawal({ accountNo: accountNo });
            if (result.success) {
                this.tips = result.data.tips
            }
            this.selectRow.money = 0
        }
    }
};
</script>

<style scoped>

</style>
