<template>
    <div style="display:flex">
        <div class="account-profit">
            <h3 class="h3flex"><span>分润账户({{ showName }})</span><a @click="open = true">切换账号</a></h3>
            <el-row>
                <el-col :span="8" class="item-title">账户总金额：</el-col>
                <el-col :span="12" class="item-value">￥{{ totalBalance }}</el-col>
            </el-row>
            <el-row>
                <el-col :span="8" class="item-title">计划冻结金额：</el-col>
                <el-col :span="12" class="item-value">￥{{ frozenBalance }}</el-col>
            </el-row>
            <el-button class="withdraw-btn" type="primary" size="medium" @click="withdraw">提现</el-button>
        </div>
        <CustomForm :on-fresh="showCustomForm" :isAdd="isAdd" :selectRow="selectRow" @on-close="handlerCustomFormOnclose">
        </CustomForm>
        <el-dialog title="请选择账户" :visible.sync="open" width="300px">
            <el-select style="width:100%" v-model="channelNo" placeholder="请选择账户">
                <el-option v-for="item in acqChannelList" :label="item.name" :value="item.channelNo"
                    :key="item.channelNo"></el-option>
            </el-select>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="open = false">取消</el-button>
                <el-button type="primary" @click="accountChange">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { ProfitManage } from '@/api'
import CustomForm from "./CustomForm";
import { accAdd } from '@/utils/tools'
export default {
    components: { CustomForm },
    data() {
        return {
            activityAccount: {},
            profitAccount: {},
            selectRow: {},
            showCustomForm: false,
            isAdd: false,
            frozenBalance: 0, //冻结金额
            totalBalance: 0,   //总金额
            activeProfit: { availableBalance: 0, frozenBalance: 0 },
            posProfit: { availableBalance: 0, frozenBalance: 0 },
            flowProfit: { availableBalance: 0, frozenBalance: 0 },
            otherProfit: { availableBalance: 0, frozenBalance: 0 },
            acqChannelList: [],
            channelArr: [],
            profitList: {},
            showName: {},
            open: false,
            channelNo: '',
            accountNo: '',
            
        }
    },
    async mounted() {
        const result = await ProfitManage.acqChannel.listAllChannel();
        this.acqChannelList = result && result.data || [];
        for (let i = 0; i < this.acqChannelList.length; i++) {
            // this.channelObj[this.acqChannelList[i].channelNo] = this.acqChannelList[i].channelName
            this.channelArr.push(this.acqChannelList[i].channelNo)
        }
        this.channelNo = this.acqChannelList[0].channelNo
        this.getAccountInfo()
    },
    created() {
    },
    methods: {
        getAccountInfo() {
            ProfitManage.myinfo.listMyAccount2()
                .then(res => {
                    if (res.success) {
                        let activeProfitList = res.data.activeProfit
                        let posProfitList = res.data.posProfit
                        let flowProfitList = res.data.flowProfit
                        let otherProfitList = res.data.otherProfit
                        this.profitList = {}
                        for (let i = 0; i < activeProfitList.length; i++) {
                            if (this.channelArr.includes(activeProfitList[i].channelNo)) {
                                if (!((typeof this.profitList[activeProfitList[i].channelNo] == 'object') && this.profitList[activeProfitList[i].channelNo].constructor == Array)) {
                                    this.profitList[activeProfitList[i].channelNo] = []
                                }
                                this.profitList[activeProfitList[i].channelNo].push(activeProfitList[i])
                            }
                        }
                        for (let i = 0; i < posProfitList.length; i++) {
                            if (this.channelArr.includes(posProfitList[i].channelNo)) {
                                if (!((typeof this.profitList[posProfitList[i].channelNo] == 'object') && this.profitList[posProfitList[i].channelNo].constructor == Array)) {
                                    this.profitList[posProfitList[i].channelNo] = []
                                }
                                this.profitList[posProfitList[i].channelNo].push(posProfitList[i])
                            }
                        }
                        for (let i = 0; i < flowProfitList.length; i++) {
                            if (this.channelArr.includes(flowProfitList[i].channelNo)) {
                                if (!((typeof this.profitList[flowProfitList[i].channelNo] == 'object') && this.profitList[flowProfitList[i].channelNo].constructor == Array)) {
                                    this.profitList[flowProfitList[i].channelNo] = []
                                }
                                this.profitList[flowProfitList[i].channelNo].push(flowProfitList[i])
                            }
                        }
                        for (let i = 0; i < otherProfitList.length; i++) {
                            if (this.channelArr.includes(otherProfitList[i].channelNo)) {
                                if (!((typeof this.profitList[otherProfitList[i].channelNo] == 'object') && this.profitList[otherProfitList[i].channelNo].constructor == Array)) {
                                    this.profitList[otherProfitList[i].channelNo] = []
                                }
                                this.profitList[otherProfitList[i].channelNo].push(otherProfitList[i])
                            }
                        }
                        this.getTotalProfit(this.profitList[this.channelNo])
                    }
                })
        },
        getTotalProfit(data) {
            this.totalBalance = 0
            this.frozenBalance = 0
            this.activeProfit = { availableBalance: 0, frozenBalance: 0 }
            this.posProfit = { availableBalance: 0, frozenBalance: 0 }
            for (let i = 0; i < data.length; i++) {
                this.totalBalance = accAdd(this.totalBalance + data[i].availableBalance, 2)
                this.frozenBalance = accAdd(this.frozenBalance + data[i].frozenBalance, 2)
                if (data[i].accountType == 'POS_PROFIT') {
                    this.posProfit.availableBalance = accAdd(this.posProfit.availableBalance + data[i].availableBalance, 2)
                    this.posProfit.accountNo = data[i].accountNo
                }
                if (data[i].accountType == 'ACTIVE_PROFIT') {
                    this.activeProfit.availableBalance = accAdd(this.activeProfit.availableBalance + data[i].availableBalance, 2)
                    this.activeProfit.accountNo = data[i].accountNo
                }
                if (data[i].accountType == 'FLOW_PROFIT') {
                    this.flowProfit.availableBalance = accAdd(this.flowProfit.availableBalance + data[i].availableBalance, 2)
                    this.flowProfit.accountNo = data[i].accountNo
                }
                if (data[i].accountType == 'OTHER_PROFIT') {
                    this.otherProfit.availableBalance = accAdd(this.otherProfit.availableBalance + data[i].availableBalance, 2)
                    this.otherProfit.accountNo = data[i].accountNo
                }
            }
            this.showName = data[0].channelName
        },
        handlerCustomFormOnclose() {
            this.showCustomForm = false;
            this.getAccountInfo();
        },
        async withdraw() {
            let params = {
                totalBalance: this.totalBalance,
                activeProfit: this.activeProfit.availableBalance,
                posProfit: this.posProfit.availableBalance,
                accountType: 'POS_PROFIT',
                posAccountNo: this.posProfit.accountNo,
                activeAccountNo: this.activeProfit.accountNo,
                flowAccountNo: this.flowProfit.accountNo,
                otherAccountNo: this.otherProfit.accountNo,
                money: 0
            }
            console.log(params)
            this.showCustomForm = true;
            this.selectRow = params;
            this.isAdd = true;
        },
        accountChange() {
            this.getTotalProfit(this.profitList[this.channelNo])
            this.open = false
        }
    }
}
</script>
<style lang="scss">
.h3flex {
    display: flex;

    span {
        flex: 1;
    }

    a {
        font-size: 14px;
        font-weight: normal;
        color: #66b1ff;
    }
}
</style>